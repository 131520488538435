@import '../../../scss//variables';

.form {
  border-radius: 20px;
  border: groove 2px $neonblue;
}


.input {
  background-color: $dark-gray !important;
  border-color: $lighter-gray !important;
  color: white !important;

  &:placeholder {
    color: $lighter-gray !important;
  }
}

.sendButton {
  padding: .8rem 4rem;
}

.center_div{
  margin: 0 auto;
  width:80% /* value of your choice which suits your alignment */
}

.verified_mark {
  height: 100px;
  width: 100px;
}