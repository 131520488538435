@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.wrapper {
  padding: 5rem 0 0 0;
  background: linear-gradient(48.69deg, #343F50 26.77%, #222831 77.85%, #0F0F0F 127.8%);

  @include media-breakpoint-up(md) {
    padding-bottom: 5rem;
  }
}

.signatures {
  transform: translateY(70px);

  @include media-breakpoint-up(md) {
    transform: none;
  }
}

.imagebutton {
  width:200px;
  @include media-breakpoint-down(md) {
    width: 150px;
  }
  
}