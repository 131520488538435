@import '../../scss/variables';

.logoText {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px !important;
}

.emailInput {
  border-color: $lighter-gray !important;
  color: white !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;

  &:placeholder {
    color: $lighter-gray !important;
  }
}

.footerLinks {

  > li {

    &:not(:last-child):after {
      content: '|';
      padding: 0 10px;
    }

    > a {
      text-decoration: none;
      color: inherit !important;
    }
  }
}
