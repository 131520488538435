@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.wrapper {
  background:
    linear-gradient(180deg, rgba(28, 28, 30, 0) 0%, #1C1C1E 66.15%),
    linear-gradient(48.69deg, #343F50 26.77%, #222831 77.85%, #0F0F0F 127.8%);
}

.buttons {
  position: relative;
  z-index: 1;

  a {
    color: white;
    padding: .8rem 3rem;
  }
}

.phone1 {
  max-width: 100%;
  transform: translateY(-25px);
}

.phone2 {
  max-width: 50%;
  transform: translateY(-25px);

  @include media-breakpoint-up(md) {
    transform: translateY(50px);
  }
}

.qrCube {

  position: absolute;
  bottom: 0;
  left: -50%;
  width: 150%;

  @include media-breakpoint-up(lg) {
    left: 0;
    width: 50%;
  }
}
