$blue: #3784f6;
$darker-gray: #1c1c1e;
$dark-gray: #2f2f2f;
$gray: #3a3a3c;
$light-gray: #565656;
$lighter-gray: #727375;
$lightest-gray: #aeaeae;
$light: #e5e5e5;
$white: #ffffff;
$neonblue: #1F51FF;
$silver: #D6D6D6;

$border-radius: .5rem;
$top-padding: 78px;
$transition: ease-in .3s;
