@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import '../../../../scss/variables';

.priceCard {
  border: solid 1px $blue;
  border-radius: 20px;
  padding: 1.5rem;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  user-select: none;
  transition: transform $transition;

  &.active, &:hover {
    background: linear-gradient(180deg, #343F50 0%, #222831 100%);
  }

  @include media-breakpoint-up(md) {
    height: 100%;

    &.active, &:hover {
      transform: scale(1.1);
    }
  }
}

.largeMargin {
  margin-top: 7rem;
  margin-bottom: 7rem;
}
