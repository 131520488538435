@import '../../../../scss//variables';

.form {
  border-radius: 20px;
  border: solid 1px $blue;
}

.input {
  background-color: $dark-gray !important;
  border-color: $lighter-gray !important;
  color: white !important;

  &:placeholder {
    color: $lighter-gray !important;
  }
}

.sendButton {
  padding: .8rem 4rem;
}
