@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import '../../../scss/variables';

// Hack to enable offset with hash navigation
.page {
  margin-top: -$top-padding;
  padding-top: $top-padding;
}
