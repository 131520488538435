@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');

@import './variables';

body {
  font-family: 'Inter', sans-serif;
  background-color: $dark;
  color: $white;
}

.offset-page {
  margin-top: $top-padding;
}
