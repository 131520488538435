@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import '../../scss/variables';

// Hack to enable offset with hash navigation
.page {
  margin-top: -$top-padding;
  padding-top: $top-padding;
}

.wrapper {
  background:
    linear-gradient(180deg, rgba(28, 28, 30, 0) 0%, #1C1C1E 66.15%),
    linear-gradient(48.69deg, #343F50 26.77%, #222831 77.85%, #0F0F0F 127.8%);
}
.accordion-button {
  background-color:#1c1c1e;
}


.buttons {
  position: relative;
  z-index: 1;

  a {
    color: white;
    padding: .8rem 3rem;
  }
}

h2 {
  font-weight: normal;
  margin: 35px 0;
  color: white;

}

p {
  font-weight: lighter;
}

