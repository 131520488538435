@import './variables';

// Override Bootstrap default variables
$primary: $blue;
$dark: $darker-gray;

// Necessary before utility overrides
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

// Add custom theme colors
$theme-colors: map-merge(
  $theme-colors,
  (
    'gray': $gray,
    'light-gray': $light-gray,
    'lighter-gray': $lighter-gray,
    'lightest-gray': $lightest-gray,
    'light': $light,
    'white': $white,
  )
);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, '$key', 'text');
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, '$key', 'bg');

$font-sizes: map-merge(
  $font-sizes,
  (
    11: 11px,
    12: 12px,
    13: 13px,
    14: 14px,
    16: 16px,
  )
);

@import 'bootstrap/scss/bootstrap';
